<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title">Fail</h1>
      <div class="container text-center">
        <p>
          Your patreon account failed to link with your steam account. Are you pledged to
          <a
            href="https://www.patreon.com/dotacinema"
            target="_blank"
          >DotaCinema</a>?
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>